import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import '../../app/contactus/contactus.css';
import Contactusimg from '../../asset/contactus.png'


const Contactus = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/" />
            </Helmet>
            <div className={'contactus_bg'}>
                <div className={'pad'}>
                    <h6>Contact Us</h6>
                    <p><span>Home / </span>Contact Us</p>
                </div>
            </div>
            <div className={'contactus_cont'}>
                <div className={'pad'}>
                    <div className={'row'}>
                        <div className={'col-lg-5'}>
                            <img src={Contactusimg} width="100%" />
                        </div>
                        <div className={'col-lg-7'}>
                            <div>
                                <Form>
                                    <div className={'row mt-5 px-2 contactus_form'}>
                                        <div className={'col-lg-6 col-md-4 col-sm-12'}>
                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label className={'f_lable'}>Name<span>*</span></Form.Label>
                                                <Form.Control className="py-2" type="text" placeholder="Enter name" />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-lg-6 col-md-4 col-sm-12'}>
                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label className={'f_lable'}>Phone Number<span>*</span></Form.Label>
                                                <Form.Control className="py-2" type="number" placeholder="Enter phone" />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-lg-6 col-md-4 col-sm-12'}>
                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label className={'f_lable'}>Email address<span>*</span></Form.Label>
                                                <Form.Control className="py-2" type="email" placeholder="Enter email" />
                                            </Form.Group>
                                        </div>


                                        <div className={'col-lg-6 col-md-4 col-sm-12'}>
                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label className={'f_lable'}>Enquiry Type<span>*</span></Form.Label>
                                                <Form.Select className="py-2" aria-label="Default select example">
                                                    <option> -----select------ </option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className={'col-lg-12 col-md-4 col-sm-12'}>
                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Label className={'f_lable'}>Message<span>*</span></Form.Label>
                                                <Form.Control className="py-2" as="textarea" placeholder="Enter message" style={{ height: '100px' }} />
                                            </Form.Group>
                                        </div>

                                        <div>
                                            <Button className={'mt-4'} variant="primary" type="submit" width="100%" >
                                                Submit
                                            </Button>
                                        </div>

                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className={'col-lg-12 mt-5'}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62185.548423918604!2d80.23289565639647!3d13.061318793961636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267d20e9dec1d%3A0xa2e2e142645a71c4!2sHOMEE%20-%20FIRST%20GEN%20ECOM!5e0!3m2!1sen!2sin!4v1676022939178!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactus;