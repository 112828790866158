import React from 'react';
import '../../../homeeservices/services/services.css';
import Homeimg from '../../../../../../asset/homemade.png';
import Homemadeicon from '../../../../../../asset/icons/homemade.png';
import { Helmet } from 'react-helmet';

const Homemadepage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Homemade Food | Homely Varieties of Delicious Food for Every Plate</title>
        <meta name="title" content="Homemade Food | Homely Varieties of Delicious Food for Every Plate" />
        <meta name="description" content="Homely Varieties offers a wide range of delicious food that caters to every plate. From quick snacks to hearty meals, we have something for everyone. " />
        <meta name="keywords" content="home chief, delivered to your door, delicious food, meals" />
      </Helmet>
      <div className={'homemade_bg'}>
        <div className={'pad'} >
          <h6>Homemade</h6>
          <p><span>Home / </span> Homemade</p>
        </div>
      </div>
      <div className={'Homemade_content'}>
        <div className={'pad'}>
          <div className={'row'}>
            <div className={'col-lg-3 col-md-3 col-sm-12'}>
              <img src={Homeimg} width='100%' />
            </div>
            <div className={'col-lg-9 col-md-9 col-sm-12'}>
              <div className={'homemade_icon'}>
                <p>  <img src={Homemadeicon} width={'100%'} /> Homemade Food</p>
              </div>
              <div className={'homemade_cont'}>
                <p>Looking for a way to eat healthier and save money? Look no further than
                  homemade food! Homemade meals and snacks are made with fresh, whole ingredients that are often
                  lower in preservatives, additives, and artificial flavors than fast food or pre-packaged meals. Whether
                  following a specific diet or simply wanting to eat well, homemade food can be tailored to meet your
                  needs. But the benefits of homemade food go beyond health and finances. With home-cooked
                  delicious foods,
                  <br></br> <br></br>
                  HOMEE allows you to bond with family members or friends over shared meals. And
                  with so many recipes and flavor combinations to explore, the possibilities are endless. So why wait?
                  Start enjoying the many benefits of homemade food today!
                </p>
              </div>
            </div>
            <div className={' homemade_cont col-lg-12 mt-3'}>
              {/* <p>Whether following a specific diet or simply wanting to eat well, homemade food can be tailored to meet your needs. But the benefits of homemade food go beyond health and finances. With home-cooked delicious foods,
                HOMEE allows you to bond with family members or friends over shared meals And
                with so many recipes and flavor combinations to explore, the possibilities are endless.
                So why wait?
                Start enjoying the many benefits of homemade food today!

              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homemadepage;