import React from 'react'
import { Container } from 'react-bootstrap';
import '../../../../home/section/homeeservices/services/services.css';
import Homemadeicon from '../../../../../asset/icons/homemade.png';
import {BsFillArrowRightCircleFill} from 'react-icons/bs'


const Homemade = () => {
  return (
    <div className={'text-center'}>
        <Container>
            <div className={'homemade'}>
                <img src={Homemadeicon}/>
               
            </div>
            <div className={"homemade_head"}>
            <h5>Homemade Food</h5>
            <p>Read More <span><BsFillArrowRightCircleFill /></span> </p>
            </div>
           
        </Container>
    </div>
  )
}

export default Homemade;