import axios from 'axios';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

function RefferalCampaign() {


    const { type, mobile } = useParams();

    var userAgent = navigator.userAgent;
    var deviceType = '';
    var deviceOsVersion = '';

    function getDeviceInfo() {
        var deviceInfo = {
            userAgent: userAgent,
            isMobile: /Mobi|Android/i.test(userAgent),
            isiOS: /iPad|iPhone|iPod/i.test(userAgent),
            isAndroid: /Android/i.test(userAgent),
            isWindows: /Windows/i.test(userAgent),
            isMac: /Macintosh/i.test(userAgent),
            isLinux: /Linux/i.test(userAgent)
        };
        if (deviceInfo.isAndroid) deviceType = 'android';
        else if (deviceInfo.isiOS) deviceType = 'ios';
        else deviceType = 'other';

        // deviceInfo.isAndroid ? (isAndroid = true) : deviceInfo.isiOS ? (isIOS == true) : null;


        // deviceInfo.isAndroid ? window.location.href = 'https://play.google.com/store/apps/details?id=com.homeeuser' :
        //     deviceInfo.isiOS ? window.location.href = 'https://apps.apple.com/us/app/homee/id1603826750' : alertAndRedirect()


        // alert("User Agent: " + deviceInfo.userAgent +
        //     "\nIs Mobile: " + deviceInfo.isMobile +
        //     "\niOS: " + deviceInfo.isiOS +
        //     "\nAndroid: " + deviceInfo.isAndroid +
        //     "\nWindows: " + deviceInfo.isWindows +
        //     "\nMac: " + deviceInfo.isMac +
        //     "\nLinux: " + deviceInfo.isLinux);

        //     // You can now use the device information for your purposes
    }
    function getDeviceType() {

        var regex = /\(([^)]+)\)/;
        var match = userAgent.match(regex);

        if (match && match.length > 1) {
            var extractedText = match[1];
            var dataArray = extractedText.split(';');
            deviceOsVersion = dataArray[1].trim();
        } else {
            console.log("No match found.");
        }

    }

    async function postCampaignData() {

        const data = {
            mobile: mobile ?? '',
            type,
            deviceType: deviceType,
            deviceOsVersion: deviceOsVersion
        };

        console.log("Data", data);

        axios.post("http://live.homeefoodz.com/public/crmapi/user_campaign_link", data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                const data = response.data;
                console.log("data from api response post  ===  ", data);
                if (data.status == 'success') window.location.href = data.url
                // console.log(response);
            })
            .catch(function (error) {
                console.error('Error making POST request:', error);
                // console.log(error);
            });

    }
    useEffect(() => {
        getDeviceType();
        getDeviceInfo();
        postCampaignData();
    }, []);

    return (
        <></>
    )
}

export default RefferalCampaign