import React from 'react';
import '../../app/career/career.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Careerimg from '../../asset/career.png';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Mern from './careerform/ReactJS';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Mernicon from '../../asset/icons/mern.png';
import Native from '../../asset/icons/2.png';
import Php from '../../asset/icons/3.png';
import Content from '../../asset/icons/4.png';
import Bd from '../../asset/icons/5.png';
import Uiux from '../../asset/icons/7.png';
import Graphic from '../../asset/icons/6.png';
import Video from '../../asset/icons/8.png';



const Career = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Career | Join Homee - Find Your Dream Career Opportunity Today </title>
                <meta name="title" content="Career | Join Homee - Find Your Dream Career Opportunity Today" />
                <meta name="description" content="Homee is the ultimate destination for your
job search. Find your dream job with our expansive list
of career opportunities and apply today. With Homee," />
                <meta name="keywords" content="career, homee, job search" />

            </Helmet>
            <div className='career_head_bg'>
                <div className={'pad'}>
                    <h3>Career</h3>
                    <p>Home / <span>Career</span></p>
                </div>
            </div>
            <div className={'pad'}>
                <div className={'career_head_content'}>
                    <div className={'row '}>
                        <div className={'col-lg-4 col-md-4'}>
                            <img src={Careerimg} width="100%" />
                        </div>
                        <div className={'col-lg-8 col-md-8 py-4 px-4'}>
                            <h6>Let's Work Together!!</h6>
                            <h6>Grow Together!!</h6>
                            <p>We're always on the lookout for talented individuals who are passionate about food,
                                customer service, and technology. Our app is revolutionizing the way people order and receive food,
                                and we're looking for team members who can help us take it to the next level. We offer a variety of
                                positions in fields such as marketing, customer service, operations, and technology. Whether you're
                                an experienced professional or just starting your career, we have opportunities for growth and
                                development.
                                <br />

                                At HOMEE, we value teamwork, creativity, and innovation. We encourage our employees to think
                                outside the box and come up with new ideas that can improve our app and customer experience. We
                                also believe in work-life balance and offer flexible schedules to help our employees achieve their
                                personal and professional goals. Browse our current job openings and apply today to join our team!

                            </p>
                        </div>

                        <div className={'col-lg-12 mt-5'}>
                            <div className={'row'}>
                                <div className={'col-lg-3'}>
                                    <Card className="text-center">
                                        <Card.Img variant="top" src={Mernicon} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Card.Body>
                                            <Card.Title><h5 style={{ fontSize: '19px', color: '#028149' }}>React JS</h5></Card.Title>
                                            <Card.Text style={{ textAlign: 'center' }}>
                                                We are seeking a skilled React JS developer to join our team. In this role, you will be
                                                responsible for building and maintaining web applications using React JS
                                            </Card.Text>
                                            <Link to={'/reactjs'}>
                                                <Button variant="primary">Apply Now</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-lg-3'}>
                                    <Card className="text-center">
                                        <Card.Img variant="top" src={Native} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Card.Body>
                                            <Card.Title><h5 style={{ fontSize: '19px', color: '#028149' }} >React Native</h5></Card.Title>
                                            <Card.Text style={{ textAlign: 'center' }}>
                                                We are seeking a skilled React Native developer to join our team. In this role, you will
                                                be responsible for building and maintaining mobile applications using React Native
                                            </Card.Text>
                                            <Link to={'/reactnative'}>
                                                <Button variant="primary">Apply Now</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-lg-3'}>
                                    <Card className="text-center">
                                        <Card.Img variant="top" src={Php} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Card.Body>
                                            <Card.Title><h5 style={{ fontSize: '19px', color: '#028149' }} >JAVA</h5></Card.Title>
                                            <Card.Text style={{ textAlign: 'center' }}>
                                                We are seeking a skilled Java developer to join our team. In this role, you will be responsible
                                                for designing and developing high-quality, scalable software applications using Java
                                            </Card.Text>
                                            <Link to={'/java'}>
                                                <Button variant="primary">Apply Now</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-lg-3'}>
                                    <Card className="text-center">
                                        <Card.Img variant="top" src={Content} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Card.Body>
                                            <Card.Title><h5 style={{ fontSize: '19px', color: '#028149' }} >FIREBASE</h5></Card.Title>
                                            <Card.Text style={{ textAlign: 'center' }}>
                                                We are seeking a skilled Firebase developer to join our team. In this role, you will be
                                                responsible for designing, developing, and maintaining Firebase-based applications for our clients.
                                            </Card.Text>
                                            <Link to={'/firebase'}>
                                                <Button variant="primary">Apply Now</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-lg-3 mt-4'}>
                                    <Card className="text-center">
                                        <Card.Img variant="top" src={Bd} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Card.Body>
                                            <Card.Title><h5 style={{ fontSize: '19px', color: '#028149' }} >NODE.JS </h5></Card.Title>
                                            <Card.Text style={{ textAlign: 'center' }}>
                                                We are seeking a highly skilled Node.js Developer to join our team. As a Node.js
                                                Developer
                                            </Card.Text>
                                            <Link to={'/nodejs'}>
                                                <Button variant="primary">Apply Now</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className={'col-lg-3 mt-4'}>
                                    <Card className="text-center">
                                        <Card.Img variant="top" src={Graphic} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Card.Body>
                                            <Card.Title><h5 style={{ fontSize: '19px', color: '#028149' }} >GRAPHIC DESIGNER </h5></Card.Title>
                                            <Card.Text style={{ textAlign: 'center' }}>
                                                We are looking for a talented and creative Graphic Designer to join our team.
                                                As a Graphic Designer
                                            </Card.Text>
                                            <Link to={'/graphicdesigner'}>
                                                <Button variant="primary">Apply Now</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className={'col-lg-3 mt-4'}>
                                    <Card className="text-center">
                                        <Card.Img variant="top" src={Uiux} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Card.Body>
                                            <Card.Title><h5 style={{ fontSize: '19px', color: '#028149' }} >UI & UX DESIGNER </h5></Card.Title>
                                            <Card.Text style={{ textAlign: 'center' }}>
                                                We are seeking a talented and experienced UI/UX Designer to join our team. As
                                                a UI/UX Designer.
                                            </Card.Text>
                                            <Link to={'/ui&uxdesigner'}>
                                                <Button variant="primary">Apply Now</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-lg-3 mt-4'}>
                                    <Card className="text-center">
                                        <Card.Img variant="top" src={Video} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} />
                                        <Card.Body>
                                            <Card.Title><h5 style={{ fontSize: '19px', color: '#028149' }} >VIDEO EDITOR</h5></Card.Title>
                                            <Card.Text style={{ textAlign: 'center' }}>
                                                We are looking for a skilled and experienced Video Editor to join our team. As a
                                                Video Editor
                                            </Card.Text>
                                            <Link to={'/videoeditor'}>
                                                <Button variant="primary">Apply Now</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Career;