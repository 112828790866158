import React from 'react';
import '../../../homeeservices/services/services.css';
import Homeimg from '../../../../../../asset/restaurant.png';
import Resticon from '../../../../../../asset/icons/rest.png';
import { Helmet } from 'react-helmet';


const Restaurantpage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Restaurant Food | Easily Order Your Favourite Restaurant Dishes Online</title>
        <meta name="title" content="Restaurant Food | Easily Order Your Favourite Restaurant Dishes Online" />
        <meta name="description" content="Get your favorite dishes delivered right to
your door with easy online ordering from your local
restaurant. Get delicious meals, fast and hassle-free" />
        <meta name="keywords" content="delicious meals, local restaurant, online ordering" />

      </Helmet>
      <div className={'homemade_bg'}>
        <div className={'pad'} >
          <h6>Restaurant</h6>
          <p><span>Home / </span> Restaurant</p>
        </div>
      </div>
      <div className={'Homemade_content'}>
        <div className={'pad'}>
          <div className={'row'}>
            <div className={'col-lg-3 col-md-3 col-sm-12'}>
              <img src={Homeimg} width='100%' />
            </div>
            <div className={'col-lg-9 col-md-9 col-sm-12'}>
              <div className={'rest_icon'}>
                <p>  <img src={Resticon} /> Restaurant</p>
              </div>
              <div className={'homemade_cont'}>
                <p> Looking for a quick and convenient way to enjoy a delicious meal? Look no
                  further than restaurant food! HOMEE offers a wide range of cuisines and dishes to suit different tastes
                  and dietary requirements. From fast food to fine dining, there's something for everyone. Whether
                  you're looking for a quick lunch on the go or a leisurely dinner with friends, there's no denying the
                  convenience and enjoyment of dining out.

                  <br></br>  <br></br>
                  HOMEE now brings the dining to your home right away!
                  Just be sure to make informed choices and balance your restaurant meals with homemade meals to
                  maintain a healthy and balanced diet.
                </p>
              </div>
            </div>
            <div className={' homemade_cont col-lg-12 mt-3'}>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem
                Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing

              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Restaurantpage;