import React from 'react';
import Slider from 'react-slick';
import '../../../app/home/section/carousel.css';


import Data from '../../../helper/data';
import useMediaQuery from '@mui/material/useMediaQuery';

import Banner1 from '../../../asset/banner/banner3.png';
import Banner2 from '../../../asset/banner/banner.png';
import Banner3 from '../../../asset/banner/banner3.jpg';


import Mobbanner1 from '../../../asset/banner/banner4.jpg';
import Mobbanner2 from '../../../asset/banner/banner5.jpg';
import Mobbanner3 from '../../../asset/banner/banner6.jpg';
import Mobbanner4 from '../../../asset/banner/banner7.jpg';
import Mobbanner5 from '../../../asset/banner/banner8.jpg';

const Carousel = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    prevArrow: false,
    nextArrow: false,



  };

  const is430Below = useMediaQuery('(min-width:430px)')

  return (
    <div>

      {
        is430Below ? (
          <Slider {...settings}>
                  <div>
                  <img src={Banner1} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
                  <div>
                  <img src={Banner2} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
                  <div>
                  <img src={Banner3} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
          {/* {
            Data[1].banners.map((value) => {
              return (
                <div>
                  <img src={value.bg_img} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
              )
            })
          } */}



        </Slider>
       
        ) : (
          <Slider {...settings}>
              <div>
                  <img src={Mobbanner1} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
              <div>
                  <img src={Mobbanner2} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
              <div>
                  <img src={Mobbanner3} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
              <div>
                  <img src={Mobbanner4} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
              <div>
                  <img src={Mobbanner5} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
          {/* {
            Data[1].banners.map((value) => {
              return (
                <div>
                  <img src={value.bg_img} width="100%" style={{ maxHeight: '50vh' }} />
                </div>
              )
            })
          } */}



        </Slider>

        )
      }


    </div>
  )
}

export default Carousel;