import React from 'react';
import Headicon from '../../../../asset/headicon.png';
import '../../../../app/home/section/gallery/gallery.css';
import ModalImage from "react-modal-image";
import G1 from '../../../../asset/gallery/g1.png';
import G2 from '../../../../asset/gallery/g2.png';
import G3 from '../../../../asset/gallery/g3.png';
import G4 from '../../../../asset/gallery/g4.png';
import G5 from '../../../../asset/gallery/g5.png';
import G6 from '../../../../asset/gallery/g6.png';
import G7 from '../../../../asset/gallery/g7.png';
import G8 from '../../../../asset/gallery/g8.png';
import G9 from '../../../../asset/gallery/g9.png';
import G10 from '../../../../asset/gallery/g10.png';
import G11 from '../../../../asset/gallery/g11.png';
import G12 from '../../../../asset/gallery/g12.png';
import G13 from '../../../../asset/gallery/g13.png';
import G14 from '../../../../asset/gallery/g14.png';




const Gallery = () => {
    return (
        <div className={'gallery'}>
            <div className={'pad'}>
                <div className={'row '}>
                    <div className={'col-lg-12 text-center headicon'}>
                        <img src={Headicon} />
                        <h1 className={'mt-1'}>Food <span>Gallery</span></h1>
                    </div>
                    <div className={'col-lg-2 col-md-2 col-sm-12 mt-4'}>
                        <div className='row'>
                            <div className={'col-lg-12'}>
                                <ModalImage
                                    small={G1}
                                    large={G1}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}

                                />

                            </div>
                            <div className={'col-lg-12 mt-4'}>
                                <ModalImage
                                    small={G7}
                                    large={G7}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />

                            </div>

                        </div>

                    </div>
                    <div className={'col-lg-4 col-md-4 col-sm-12 mt-4'}>
                        <div className={'row'}>
                            <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                <ModalImage
                                    small={G2}
                                    large={G2}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />
                            </div>
                            <div className={'col-lg-6 col-md-6 col-sm-12' }>
                                <ModalImage
                                    small={G3}
                                    large={G3}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />
                            </div>

                            <div className={'col-lg-12 mt-4'}>
                                <ModalImage
                                    small={G8}
                                    large={G8}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />
                            </div>

                        </div>


                    </div>

                    <div className={'col-lg-4 col-md-4 col-sm-12 mt-4'}>
                        <div className={'row'}>
                            <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                <ModalImage
                                    small={G4}
                                    large={G4}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />
                            </div>
                            <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                <ModalImage
                                    small={G5}
                                    large={G5}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />
                            </div>
                            <div className={'col-lg-12  mt-4'}>
                                <ModalImage
                                    small={G9}
                                    large={G9}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />
                            </div>

                        </div>


                    </div>

                    <div className={'col-lg-2 col-md-2 col-sm-12 mt-4'}>
                        <div className={'row'}>
                            <div className={'col-lg-12'}>
                                <ModalImage
                                    small={G6}
                                    large={G6}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />
                            </div>
                            <div className={'col-lg-12 mt-4'}>
                                <ModalImage
                                    small={G10}
                                    large={G10}
                                    alt="Hello World!"
                                    className='image_small'
                                    hideDownload={true}


                                />
                            </div>

                        </div>

                    </div>
                    <div className={'col-lg-4 col-md-4 col-sm-12 mt-4'}>

                        <ModalImage
                            small={G11}
                            large={G11}
                            alt="Hello World!"
                            className='image_small'
                            hideDownload={true}


                        />
                    </div>
                    <div className={'col-lg-2 col-md-2 col-sm-12 mt-4'}>

                        <ModalImage
                            small={G12}
                            large={G12}
                            alt="Hello World!"
                            className='image_small'
                            hideDownload={true}


                        />
                    </div>
                    <div className={'col-lg-2 col-md-2 col-sm-12 mt-4'}>

                        <ModalImage
                            small={G13}
                            large={G13}
                            alt="Hello World!"
                            className='image_small'
                            hideDownload={true}


                        />
                    </div>
                    <div className={'col-lg-4 col-md-4 col-sm-12 mt-4'}>

                        <ModalImage
                            small={G14}
                            large={G14}
                            alt="Hello World!"
                            className='image_small'
                            hideDownload={true}


                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gallery;