import React from 'react';
import { Helmet } from 'react-helmet';
import '../Refund & Cancellation/RefundCancellation.css'


const RefundCancellation = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cancellation and Refund Policy - Homee Onetouch Platform</title>
        <link rel="canonical" href="https://homeeonetouchplatform.com/RefundCancellation" />
      </Helmet>
      <div className={'our_team_bg'}>
        <div className={'pad'}>
          <div className={'our_team_head'}>
            <h5>Refund & Cancellation</h5>
            <p>Home / <span>Refund & Cancellation</span></p>
          </div>
        </div>
      </div>


      <div className={'pad'}>
        <div className={'row mt-5'}>
          <div className={'col-lg-6'}>
            <p> Any order can be cancelled within 60 seconds of payment. If so, the refund will be sent back within 24 hours
              of the order cancellation. No order can be cancelled beyond <b>60 seconds after being placed. </b></p>
            <p>Thanks, and regards </p>
          </div>

          <div className={'col-lg-6'}>
            <p> to start preparing their food, and that too on the previous day only. This planning will be helpful to the HOME Maker
              who becomes a cook in HOMEE. They will be able to plan their cooking and menu choices, and cooks will be able to earn more
              by displaying their favourite food with good taste.</p>

            <p> HOMEE PLATFORM PROVIDING THESE FACILITIES to make all the HOME Makers independent in their financial needs to the maximum extent
              possible by using their skill of cooking and presenting the foods to the users</p>
          </div>

        </div>

      </div>

    </div>
  )
}

export default RefundCancellation
