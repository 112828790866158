import React from 'react'
import { Container } from 'react-bootstrap';
import Planticon from '../../../../../asset/icons/plant.png';
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const Plantnnursery = () => {
    return (
        <div className={'text-center'}>
            <Container>
                <div className={'plant'}>
                    <img src={Planticon} />
                </div>
                <div className={'plant_head'}>
                    <h5>Plant & Nursery</h5>
                    <p>Read More <span><BsFillArrowRightCircleFill /></span></p>
                </div>

            </Container>
        </div>
    )
}

export default Plantnnursery;