import React from 'react';
import Atimg from '../../../../asset/at.png';
import '../../../../app/home/section/abouthomee/abouthomee.css';
import Atlogo from '../../../../asset/alogo.png';
import { BsFillArrowRightCircleFill } from 'react-icons/bs'

const Abouthomee = () => {
    return (
        <div className={'abg'}>
            <div className={'pad'}>
                <div className={'row'}>
                    <div className={'col-lg-4 col-md-12 col-sm-12'}>
                        <img src={Atimg} width='100%' />

                    </div>
                    <div className={'col-lg-8 col-md-12 col-sm-12  at_content px-5'}>
                        <h1>About <span>Homee</span></h1>
                        <div className={"at_div"}>
                            <div >
                                <img src={Atlogo} />
                            </div>
                            <div>
                                <p> At HOMEE, we believe that everyone should have access to delicious and healthy
                                    meals, no matter how busy their schedule is. That's why we created an easy-to-use platform that
                                    connects busy individuals with top-rated chefs and restaurants in their area.
                                    <br />
                                    Our mission is to simplify the process of ordering food by offering a wide variety of dishes that cater to
                                    all dietary needs and preferences. From vegan and gluten-free options to classic comfort foods, our
                                    app has something for everyone.

                                </p>
                            </div>
                        </div>
                        <p> We're passionate about supporting local businesses and connecting our users with the best food
                            options in their community. Our user-friendly interface and streamlined ordering process make it easy
                            for anyone to order and enjoy their favorite meals with just a few taps.
                            <br />
                            Join us on our mission to make delicious and healthy food accessible to all. Download HOMEE today
                            and start exploring a world of flavors from the comfort of your own home.
                        </p>

                        <button>Read More <span><BsFillArrowRightCircleFill /></span></button>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default Abouthomee;