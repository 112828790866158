import React from 'react';
import { Helmet } from 'react-helmet';
import '../../app/ourteam/ourteam.css';
import Teamav from '../../asset/ourteam/teamav.png';


const Ourteam = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Our Team - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/" />
            </Helmet>
            <div className={'our_team_bg'}>
                <div className={'pad'}>
                    <div className={'our_team_head'}>
                        <h5>Our Team</h5>
                        <p>Home / <span>Ourteam</span></p>
                    </div>
                </div>
            </div>



            <div className={'our_team_contentbg'}>
                <div className={'pad'}>
                    <div className={'row'}>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-2 col-md-3 col-sm-6 col-6 our_team_content text-center'}>
                            <img src={Teamav} width='100%' />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Ourteam;