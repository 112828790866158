import React from 'react';
import Slider from 'react-slick';
import '../../../../app/home/section/ourpartners/ourpartner.css';
import Headicon from '../../../../asset/headicon.png';
import Ptlogo1 from '../../../../asset/ourpartners/pt1.png';
import Ptlogo2 from '../../../../asset/ourpartners/pt2.png';
import Ptlogo3 from '../../../../asset/ourpartners/pt3.png';
import Ptlogo4 from '../../../../asset/ourpartners/pt4.png';
import Ptlogo5 from '../../../../asset/ourpartners/pt5.png';
import Ptlogo6 from '../../../../asset/ourpartners/pt6.png';
import Ptlogo7 from '../../../../asset/ourpartners/pt7.png';
import Ptlogo8 from '../../../../asset/ourpartners/pt8.png';
import Ptlogo9 from '../../../../asset/ourpartners/pt9.png';
import Ptlogo10 from '../../../../asset/ourpartners/pt10.png';
import Ptlogo11 from '../../../../asset/ourpartners/pt11.png';
import Ptlogo12 from '../../../../asset/ourpartners/pt12.png';
import Ptlogo13 from '../../../../asset/ourpartners/pt13.png';
import Ptlogo14 from '../../../../asset/ourpartners/pt14.png';
import Ptlogo15 from '../../../../asset/ourpartners/pt15.png';

const Ourpartners = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className={'partner_bg'}>
            <div className={'pad'}>
                <div className={'row'}>
                    <div className={'col-lg-12 text-center headicon'}>
                        <img src={Headicon} />
                        <h1>Our <span>Partners</span></h1>
                    </div>
                    <div>
                        <Slider {...settings}>
                            <div className={'p-2'}>
                                <img src={Ptlogo1} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo2} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo3} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo4} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo5} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo6} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo7} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo8} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo9} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo10} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo11} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo12} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo13} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo14} alt='ddd' width='100%' />
                            </div>
                            <div className={'p-2'}>
                                <img src={Ptlogo15} alt='ddd' width='100%' />
                            </div>



                        </Slider>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Ourpartners;