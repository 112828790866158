import React, { useState } from 'react';
import { Accordion, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../shared/header.css'
import Logo from '../asset/logo.png';
import { Drawer } from 'antd';
import { HiMenuAlt2 } from 'react-icons/hi';
import { AiFillHome } from 'react-icons/ai';
import { MdRestaurantMenu } from 'react-icons/md';
import { RiPlantFill } from 'react-icons/ri';
import { FaTruckPickup } from 'react-icons/fa';
import { MdMiscellaneousServices } from 'react-icons/md';

const Header = () => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };



    return (

        <>
            <div className={'header_bg'}>
                <div className='pad'>

                    <div className={"row py-4 "}>
                        <div className={"col-lg-2 col-md-6 col-6 homee_logo "}>
                           <a href='/'><img src={Logo} /> </a> 
                        </div>

                        <div className={"col-lg-10 col-md-6 col-6 d-flex justify-content-end align-items-center"}>
                            <div className={"header_list"}>
                                <Link to={'/'}><h6>Home</h6></Link>
                                <Link to={'aboutus'}><h6>About Us</h6></Link>
                                <div className={"hover_service"}>
                                    <h6 >Homee Services</h6>
                                    <div className={'services'}>
                                        <Link to={'/homemade'}><p><span><AiFillHome /></span> Homemade Food</p></Link>
                                        <Link to={'/restaurantfood'}><p><span><MdRestaurantMenu /></span> Restaurant Food</p></Link>
                                        <Link to={'/plant&nursery'}><p><span><RiPlantFill /></span> Plant & Nursery</p></Link>
                                        <Link to={'/Pickup&drop'}><p><span><FaTruckPickup /></span> Pickup & Drop</p></Link>
                                        <Link to={'/homeservices'}><p> <span><MdMiscellaneousServices /></span> Homeservice</p></Link>
                                    </div>
                                </div>
                                {/* <Link to={'/ourteam'}><h6>Our Team</h6></Link> */}
                                <Link to={'/career'}><h6>Career</h6></Link>
                                {/* <Link to={'/consultant'}><h6>consultant</h6></Link> */}
                                {/* <Link to={'/blog'}><h6>Blog</h6></Link> */}
                                <Link to={'/contactus'}><h6>Contact Us</h6></Link>
                                <a href='https://play.google.com/store/apps/details?id=com.homeeuser'> <button className={"menu_button"} >  Download Our App</button></a>
                            </div>
                            <HiMenuAlt2 className={"menu_icon"} onClick={showDrawer} />
                        </div>
                    </div>
                </div>
            </div>

            {/* <!============================= side menu start======================================================================================================================!> */}

            <Drawer title="" placement="right" onClose={onClose} open={open} style={{ zIndex: '10000' }}>
                <div className={"side_header_list"}>
                    <Link to={'/'} onClick={() => setOpen(false)}><h6>Home</h6></Link>
                    <Link to={'/aboutus'} onClick={() => setOpen(false)}><h6>About Us</h6></Link>
                    <h6>
                        <Accordion >
                            <Accordion.Item eventKey="0" style={{ border: 'none' }}>
                                <Accordion.Header className={'acc_bt'}>Homee Services</Accordion.Header>
                                <Accordion.Body >
                                    <div className={'side_menu_links'}>
                                        <Link to={'/homemade'} onClick={() => setOpen(false)}><p><span><AiFillHome /></span> Homemade Food</p></Link>
                                        <Link to={'/restaurantfood'} onClick={() => setOpen(false)}><p><span><MdRestaurantMenu /></span> Restaurant Food</p></Link>
                                        <Link to={'/plant&nursery'} onClick={() => setOpen(false)}><p><span><RiPlantFill /></span> Plant & Nursery</p></Link>
                                        <Link to={'/Pickup&drop'} onClick={() => setOpen(false)}><p><span><FaTruckPickup /></span> Pickup & Drop</p></Link>
                                        <Link to={'/homeservices'} onClick={() => setOpen(false)}><p> <span><MdMiscellaneousServices /></span> Homeservice</p></Link>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </h6>

                    {/* <Link to={'/ourteam'} onClick={() => setOpen(false)}><h6>Our Team</h6></Link> */}
                    <Link to={'/career'} onClick={() => setOpen(false)}><h6>Career</h6></Link>
                    {/* <Link to={'/consultant'} onClick={() => setOpen(false)}><h6>consultant</h6></Link> */}
                    {/* <Link to={'/blog'} onClick={() => setOpen(false)}><h6>Blog</h6></Link> */}
                    <Link to={'/contactus'} onClick={() => setOpen(false)}><h6>Contact Us</h6> </Link>
                    <a href='https://play.google.com/store/apps/details?id=com.homeeuser'> <button className={'side_menu_button'} onClick={() => setOpen(false)} >Download Our App</button> </a>
                </div>
            </Drawer>

            {/* <!============================= side menu end======================================================================================================================!> */}


        </>



    )
}

export default Header;