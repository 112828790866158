import React from 'react';
import { Helmet } from 'react-helmet';
import Aboutcookapp from './section/aboutcookapp/aboutcookapp';
import Abouthomee from './section/abouthomee/abouthomee';
import Aboutuserappindex from './section/aboutuserapp/aboutuserappindex';
import Blog from './section/blog/blog';
import Carousel from './section/carousel';
import Gallery from './section/gallery/gallery';
import Homeeserviceindex from './section/homeeservices/homeeserviceindex';
import Ourpartners from './section/ourpartners/ourpartners';







const Homeindex = () => {


  //   const [banner, setbanner] = useState([])

  //   useEffect(() => {
  //     Axios

  //       .get(`/get/brand?slug=/${slug}`)
  //       .then((res) => {
  //         setdata(res.data.data);
  //         console.log(res.data);

  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Homee Onetouch Platform | Delicious Homely & Organic Foods for Everyone</title>
        <link rel="canonical" href="https://homeeonetouchplatform.com/" />
        <meta name="title" content="Homee Onetouch Platform | Delicious Homely & Organic Foods for Everyone" />
        <meta name="description" content="Enjoy the delicious taste of home-cooked
meals prepared with love and care. We guarantee the
highest quality ingredients"/>
        <meta name="keywords" content="Delicious food, homely food, taste" />
      </Helmet>
      <Carousel />
      <Homeeserviceindex />
      <Abouthomee />
      <Aboutuserappindex />
      {/* <Blog/> */}
      <Aboutcookapp />
      <Ourpartners />
      <Gallery />





    </div>
  )
}

export default Homeindex;