import logo from './logo.svg';
import './App.css';
import Homeindex from './app/home/homeindex';
import Header from './shared/header';
import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import Aboutusindex from './app/aboutus/aboutusindex';
import Homemadepage from './app/home/section/homeeservices/services/pages/homemadepage';
import Restaurantpage from './app/home/section/homeeservices/services/pages/restaurantpage';
import Plantnnurserypage from './app/home/section/homeeservices/services/pages/plantnnurserypage';
import Pickupndroppage from './app/home/section/homeeservices/services/pages/pickupndroppage';
import Homeservicepage from './app/home/section/homeeservices/services/pages/homeservicepage';
import Footer from './shared/footer';
import Ourteam from './app/ourteam/ourteam';
import Career from './app/career/career';
import Consultant from './app/consultant/consultant';
import Foods from './app/consultant/foods/foods';
import Blog from './app/blog/blog';
import Blogpage from './app/blog/blogpage/blogpage';
import Contactus from './app/contactus/contactus';
import ReactJS from './app/career/careerform/ReactJS';
import Reactnative from './app/career/careerform/reactnative';
import Java from './app/career/careerform/java';
import Firebase from './app/career/careerform/firebase';
import Nodejs from './app/career/careerform/nodejs';
import Graphicdesigner from './app/career/careerform/graphicdesigner';
import UInUXdesigner from './app/career/careerform/uinuxdesigner';
import Videoeditor from './app/career/careerform/videoeditor';
import RefundCancellation from './app/Refund & Cancellation/RefundCancellation';
import ChefAgreement from './app/Chef Agreement/ChefAgreement';
import CaterersSaviour from './app/Caterers Saviour/CaterersSaviour'
import ScrollToTop from './helper/ScrollToTop';
import RefferalCampaign from './app/ReferralCampaign';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path='CaterersSaviour' element={<CaterersSaviour />} />
          <Route path='ChefAgreement' element={<ChefAgreement />} />
          <Route path='RefundCancellation' element={<RefundCancellation />} />
          <Route path='reactjs' element={<ReactJS />} />
          <Route path='reactnative' element={<Reactnative />} />
          <Route path='java' element={<Java />} />
          <Route path='firebase' element={<Firebase />} />
          <Route path='nodejs' element={<Nodejs />} />
          <Route path='graphicdesigner' element={<Graphicdesigner />} />
          <Route path='ui&uxdesigner' element={<UInUXdesigner />} />
          <Route path='videoeditor' element={<Videoeditor />} />
          <Route path='homeservices' element={<Homeservicepage />} />
          <Route path='Pickup&drop' element={<Pickupndroppage />} />
          <Route path='plant&nursery' element={<Plantnnurserypage />} />
          <Route path='restaurantfood' element={<Restaurantpage />} />
          <Route path='homemade' element={<Homemadepage />} />
          <Route path='contactus' element={<Contactus />} />
          <Route path='blogpage' element={<Blogpage />} />
          <Route path='blog' element={<Blog />} />
          <Route path='consultant' element={<Consultant />} />
          <Route path='career' element={<Career />} />
          <Route path='ourteam' element={<Ourteam />} />
          <Route path='aboutus' element={<Aboutusindex />} />
          <Route path='/' element={<Homeindex />} />
          <Route path='campaign/:type/:mobile?' element={<RefferalCampaign />} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
