import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Sowmya_photo from '../../../asset/aboutus/Sowmya_Photo.jpg';


const Sowmya = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <div>
                <div className={'row py-3 av'} onClick={handleShow}>
                    <div className={'col-lg-12'}>
                        <img src={Sowmya_photo} width="100%" />
                    </div>
                    <div className={'col-lg-12 mt-3 text-center'}>
                        <h5>MRS. SOWMYA RAMAN</h5>
                        <p>Director of Homee</p>
                    </div>

                </div>
                <Modal show={show} onHide={handleClose} centered size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>MRS. SOWMYA RAMAN</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Sowmya Raman is business management graduate and handling administration  & sales functions of the

                            company  since 2012.  Having experience of more then 5 years in sales in the past and worked with citi

                            bank group and ITC group of hotels .Hailing from business family and supporting Mr. Raman in taking

                            key decisions which have long term financial impact etc.

                        </p></Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
            </div>
        </div>


    )
}

export default Sowmya;