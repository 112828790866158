import React from 'react';
import '../../app/blog/blog.css';
import Data from '../../helper/data';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Homemade from '../../asset/icons/homemade.png';
import Homeservice from '../../asset/icons/homeservice.png';
import Pickup from '../../asset/icons/pickup.png';
import PLant from '../../asset/icons/plant.png';
import Rest from '../../asset/icons/rest.png';
import '../../helper/data.js'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Blog = () => {
    const handleClick = () => {
        console.info('You clicked the Chip.');
    };
    return (

        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blog - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/" />
            </Helmet>
            <div className='blog_page_bg'>
                <div className={'pad'}>
                    <div className={'blog_page_content_head'}>
                        <h3>Blog</h3>
                        <p>Home / <span>Blog</span></p>
                    </div>
                </div>
            </div>
            <div className={'blog_page_content'}>
                <div className={'pad'}>
                    <div className={'row'}>
                        <div className={'col-xl-9 col-lg-12 col-md-12 col-sm-12 '}>

                            <div className={'row'}>
                                {
                                    Data[0].blog.map((value, index) => {
                                        return (
                                            <>
                                                <div className={'col-lg-4 col-md-4 mt-3 '}>
                                                    <Card style={{ width: '100%' }}>
                                                        <Card.Img variant="top" src={value.blog_img} width='100%' />
                                                        <Card.Body>
                                                            <Card.Title className='cd_title'>
                                                                <p><img src={value.blog_category_img} style={{ backgroundColor: value.blog_category_bgcolor }} />{value.blog_category_name}</p>
                                                                <h3>{value.blog_title}</h3>
                                                            </Card.Title>
                                                            <Card.Text className={'cd_body'}>
                                                                <p>{value.blog_des}</p>
                                                            </Card.Text>
                                                            <Link to={'/blogpage'}><Button variant="primary" >Go somewhere</Button></Link>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={'col-xl-3 col-lg-12 col-md-12 mt-3  '}>
                            <div className={'row'}>
                                <div className={' col-xl-12 col-lg-6  col-md-6 col-sm-12 '}>
                                    <h1 className={'blog_side_head'}>Tags</h1>
                                    <div className={'tags'}>
                                        <div className={'row blog_tags'}>
                                            {
                                                Data[0].blog.map((value) => {
                                                    return (
                                                        <div className={'col-lg-4 col-md-4'}>

                                                            <h6>{value.tags}</h6>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>


                                </div>

                                <div className={' col-xl-12 col-lg-6  col-md-6 col-sm-12'}>
                                    <h1 className={'blog_side_head mt-xl-4 mt-sm-4'}>Date</h1>
                                    <div>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>MARCH</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        <li>25-05-2022</li>
                                                        <li>25-05-2022</li>
                                                        <li>25-05-2022</li>
                                                        <li>25-05-2022</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>MAY</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        <li>25-05-2022</li>
                                                        <li>25-05-2022</li>
                                                        <li>25-05-2022</li>
                                                        <li>25-05-2022</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className={' col-xl-12 col-lg-6  col-md-6 col-sm-12'}>

                                    <h1 className={'blog_side_head mt-xl-4 mt-sm-4'}>Category</h1>
                                    <div className={'blog_side_category'}>
                                        <h6><img src={Homemade} style={{ backgroundColor: '#E8FFF5' }} /> Homemade</h6>
                                        <h6><img src={Rest} style={{ backgroundColor: '#F5EEFF' }} /> Restaurant</h6>
                                        <h6><img src={PLant} style={{ backgroundColor: '#FFF9EC' }} /> Plant & Nursery</h6>
                                        <h6><img src={Pickup} style={{ backgroundColor: '#FFEBEB' }} /> Pickup & Drop</h6>
                                        <h6><img src={Homeservice} style={{ backgroundColor: '#F8F8F8' }} /> Home Services</h6>

                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Blog;