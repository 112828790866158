import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import '../../../app/consultant/foods/foods.css';
import Av from '../../../asset/av.png';
import Avat from '../../../asset/ourteam/teamav.png';
import Phone from '../../../asset/phone.png';
import Mail from '../../../asset/mail.png';
import Whats from '../../../asset/whats.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}



const Foods = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={' mt-5'}>
            <Box
                sx={{   }}
                className={'row'}
            >
                <Tabs
                    className={'col-lg-5 col-md-12  p-0'}
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}

                    sx={{ borderRight: 1, borderColor: 'divider', "& button.Mui-selected": { backgroundColor: 'red !important' } }}
                >
                    <Tab label="Item One" {...a11yProps(0)} component={() => (
                        <Button onClick={() => setValue(0)}>
                            <div className={'row align-items-center con_names py-3  '}>
                                <div className={'col-lg-3 col-md-3 col-sm-3 col-3 '}>
                                    <img src={Av} width="100%" />
                                </div>
                                <div className={'col-lg-8 col-md-8 col-sm-8  col-8 '}>
                                    <h5>Dhinesh Kumar</h5>
                                    <p>UI & UX Designer</p>
                                </div>
                            </div>
                        </Button>
                    )} />
                    <Tab label="Item Two" {...a11yProps(1)} component={() => (
                        <Button onClick={() => setValue(1)}>
                            <div className={'row align-items-center con_names  py-3   '}>
                                <div className={'col-lg-3 col-md-3 col-sm-3 col-3'}>
                                    <img src={Av} width="100%" />
                                </div>
                                <div className={'col-lg-8  col-md-8 col-sm-8  col-8 '}>
                                    <h5>Dhinesh Kumar</h5>
                                    <p>UI & UX Designer</p>
                                </div>
                            </div>
                        </Button>
                    )} />
                    <Tab selec label="Item Three" {...a11yProps(2)} component={() => (
                        <Button onClick={() => setValue(2)}>
                            <div className={'row align-items-center con_names py-3   '}>
                                <div className={'col-lg-3 col-md-3 col-sm-3  col-3'}>
                                    <img src={Av} width="100%" />
                                </div>
                                <div className={'col-lg-8 col-md-8 col-sm-8   col-8 '}>
                                    <h5>Dhinesh Kumar</h5>
                                    <p>UI & UX Designer</p>
                                </div>
                            </div>
                        </Button>
                    )} />
                    <Tab label="Item Four" {...a11yProps(3)} component={() => (
                        <Button onClick={() => setValue(3)}>
                            <div className={'row   con_names py-3  '}>
                                <div className={'col-lg-3  col-md-3 col-sm-3 col-3 '}>
                                    <img src={Av} width="100%" />
                                </div>
                                <div className={'col-lg-8 col-md-8 col-sm-8 col-8 '}>
                                    <h5>Dhinesh Kumar</h5>
                                    <p>UI & UX Designer</p>
                                </div>
                            </div>
                        </Button>
                    )} />

                </Tabs>
                <TabPanel className={'col-lg-7 col-md-12 '} value={value} index={0}>
                    <div className='row text-center justify-content-center con_panel_content '>
                        <div className={'col-lg-12'}>
                            <img src={Avat} />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-12 col-md-12'}>
                            <p>Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500sLorem Ipsum is simply
                                dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500sLorem
                                Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Phone} width='100%' />
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Mail} width='100%' />
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Whats} width='100%' />
                        </div>

                    </div>
                </TabPanel>
                <TabPanel className={'col-lg-7 col-md-12 '} value={value} index={1}>
                <div className='row text-center  con_panel_content '>
                        <div className={'col-lg-12'}>
                            <img src={Avat} />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-12'}>
                            <p>Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500sLorem Ipsum is simply
                                dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500sLorem
                                Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Phone} width='100%' />
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Mail} width='100%' />
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Whats} width='100%' />
                        </div>

                    </div>
                </TabPanel>
                <TabPanel className={'col-lg-7 col-md-12'} value={value} index={2}>
                <div className='row text-center con_panel_content '>
                        <div className={'col-lg-12'}>
                            <img src={Avat} />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-12'}>
                            <p>Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500sLorem Ipsum is simply
                                dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500sLorem
                                Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Phone} width='100%' />
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Mail} width='100%' />
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Whats} width='100%' />
                        </div>

                    </div>
                </TabPanel>
                <TabPanel className={'col-lg-7 col-md-12'} value={value} index={3}>
                <div className='row text-center con_panel_content '>
                        <div className={'col-lg-12'}>
                            <img src={Avat} />
                            <h5>Dhinesh Kumar</h5>
                            <p>UI & UX Designer</p>
                        </div>
                        <div className={'col-lg-12'}>
                            <p>Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500sLorem Ipsum is simply
                                dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500sLorem
                                Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Phone} width='100%' />
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Mail} width='100%' />
                        </div>
                        <div className={'col-lg-3 col-md-4 col-sm-2 col-4 '}>
                            <img src={Whats} width='100%' />
                        </div>

                    </div>
                </TabPanel>

            </Box>
        </div>
    )
}

export default Foods;