const data = [
    {
        "blog": [

            {
                'id': '01',
                'blog_date': 'This 7-Layer Beet Salad is a stunner! Studded with cooked beets, supremed oranges,....',
                'blog_img': 'https://www.linkpicture.com/q/blogimg.png',
                'blog_title': '7-Layer Beet Salad',
                'blog_des': 'This 7-Layer Beet Salad is a stunner! Studded with cooked beets, supremed oranges,....',
                'blog_category_icon': 'https://www.linkpicture.com/q/homemade.png',
                'blog_category_name': 'homemade',
                'blog_category_img': 'https://www.linkpicture.com/q/homemade_1.png',
                'blog_category_bgcolor':'#E8FFF5',
                'slug': '/slug',
                'tags': '7-Layer Beet Salad'

            },
            {
                'id': '02',
                'blog_date': '02 JAN 2023',
                'blog_img': 'https://www.linkpicture.com/q/blogimg.png',
                'blog_title': '7-Layer Beet Salad',
                'blog_des': 'This 7-Layer Beet Salad is a stunner! Studded with cooked beets, supremed oranges ddsvsdvsd sdvdsvdsv ssdvdvdss sdvdsvdsv sdvvdv',
                'blog_category_icon': 'https://www.linkpicture.com/q/homemade.png',
                'blog_category_name': 'homemade',
                'blog_category_img': 'https://www.linkpicture.com/q/homemade_1.png',
                'blog_category_bgcolor':'#E8FFF5',
                'tags': 'Beet Salad'


            },
            {
                'id': '03',
                'blog_date': 'This 7-Layer Beet Salad is a stunner! Studded with cooked beets, supremed oranges,....',
                'blog_img': 'https://www.linkpicture.com/q/blogimg.png',
                'blog_title': '7-Layer Beet Salad',
                'blog_des': 'This 7-Layer Beet Salad is a stunner! Studded with cooked beets, supremed oranges,....',
                'blog_category_icon': 'https://www.linkpicture.com/q/homemade.png',
                'blog_category_name': 'homemade',
                'blog_category_img': 'https://www.linkpicture.com/q/homemade_1.png',
                'blog_category_bgcolor':'#E8FFF5',
                'tags': '7-Layer Beet Salad'


            },
            {
                'id': '03',
                'blog_date': 'This 7-Layer Beet Salad is a stunner! Studded with cooked beets, supremed oranges,....',
                'blog_img': 'https://www.linkpicture.com/q/blogimg.png',
                'blog_title': '7-Layer Beet Salad',
                'blog_des': 'This 7-Layer Beet Salad is a stunner! Studded with cooked beets, supremed oranges,....',
                'blog_category_icon': 'https://www.linkpicture.com/q/homemade.png',
                'blog_category_name': 'homemade',
                'blog_category_img': 'https://www.linkpicture.com/q/homemade_1.png',
                'blog_category_bgcolor':'#E8FFF5',
                'tags': 'Beet Salad'


            },
        ],
    },

    {
        'banners': [
            {
                'bg_img': 'https://www.linkpicture.com/q/banner2_14.jpg',
            },
            {
                'bg_img': 'https://www.linkpicture.com/q/banner2_14.jpg',
            },
            {
                'bg_img': 'https://www.linkpicture.com/q/banner2_14.jpg',
            },
        ]

    },

    // {
    //     "banner": 'https://www.linkpicture.com/q/banner_5.png',
    // },
    // {
    //     "banner": 'https://www.linkpicture.com/q/banner2_14.png',
    // },




];






export default data;




