import React, { useState } from 'react'
import '../../app/consultant/consultant.css';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Resticon from '../../asset/icons/rest.png';
import Planticon from '../../asset/icons/plant.png';
import { Avatar, Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { Col } from 'react-bootstrap';
import Foods from './foods/foods';
import Plant from './plant/plant';
import { Helmet } from 'react-helmet';




const Consultant = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [value2, setValue2] = useState('1');

    const handleChange2 = (event, newValue2) => {
        setValue2(newValue2);
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Consultant - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/" />
            </Helmet>
            <div className={'con_bg'}>
                <div className={'pad'}>
                    <h5>Consultant</h5>
                    <p>Home / <span>Consultant</span></p>
                </div>
            </div>

            <div className={'con_content'}>
                <div className={'pad  '}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                                    <Tab value="1" component={() => (
                                        <Button onClick={() => setValue('1')}>
                                            <div className={'con_icon'}>
                                                <img src={Resticon} />
                                                <p>Foods</p>
                                            </div>
                                        </Button>
                                    )}
                                    />
                                    <Tab value="2" component={() => (
                                        <Button onClick={() => setValue('2')}>
                                            <div className={'con_icon2 text-center'}>
                                                <img src={Planticon} />
                                                <p>Plant</p>
                                            </div>
                                        </Button>
                                    )}
                                    />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <div>
                                    <Foods />
                                </div>

                            </TabPanel>
                            <TabPanel value="2">
                                <Plant />
                            </TabPanel>

                        </TabContext>
                    </Box>
                </div>

            </div>
        </div>
    )
}

export default Consultant;