import React from 'react'
import { Helmet } from 'react-helmet';
import '../../app/aboutus/aboutus.css';
import Aboutusimg from '../../asset/aboutus.png';
import Alogo from '../../asset/alogo.png';
import Raman from './raman/raman';
import Sowmya from './sowmya/sowmya';






const Aboutusindex = () => {
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Healthy Food Delivered to Your Door - Order from Home Chief</title>
        <meta name="title" content="About Us | Healthy Food Delivered to Your Door - Order from Home Chief" />
        <meta name="description" content="Home Chief makes eating healthily easier than ever. Our meals are prepared with the finest ingredients, and delivered right to your door. " />
        <meta name="keywords" content="home chief, delivered to your door" />
      </Helmet>
      <div className={'aboutus_bg'}>
        <div className={'pad'}>
          <h3>About US</h3>
          <div className={'d-flex'}>
            <p>
              Home /
            </p>
            <p>
              About Us
            </p>
          </div>

        </div>
      </div>
      <div className={'all_bg'}>
        <div className={'pad'}>
          <div className={'row'}>
            <div className={'col-lg-4 all_at_Content '}>
              <img src={Aboutusimg} width="100%" />
            </div>

            <div className={'col-lg-8 all_at_Content'}>
              <div className={'d-flex jsutify-content-center align-items-top'} >
                {/* <div>
                <img src={Alogo}  />
              </div> */}
                <div>
                  <p>
                    At HOMEE, we believe that everyone should have access to delicious and healthy
                    meals, no matter how busy their schedule is. That's why we created an easy-to-use platform that
                    connects busy individuals with top-rated chefs and restaurants in their area.
                    <br /><br />
                    Our mission is to simplify the process of ordering food by offering a wide variety of dishes that cater to
                    all dietary needs and preferences. From vegan and gluten-free options to classic comfort foods, our
                    app has something for everyone

                  </p>
                </div>
              </div>
              <div>
                <p>
                  We're passionate about supporting local businesses and connecting our users with the best food
                  options in their community. Our user-friendly interface and streamlined ordering process make it easy
                  for anyone to order and enjoy their favorite meals with just a few taps.
                  <br /> <br />
                  Join us on our mission to make delicious and healthy food accessible to all. Download HOMEE today
                  and start exploring a world of flavors from the comfort of your own home.
                </p>
              </div>
              <div className={'row'}>
                <div className={'col-lg-3 col-md-4 col-sm-6 col-6 px-3'}>
                  <Raman />
                </div>
                <div className={'col-lg-3 col-md-4 col-sm-6 col-6 px-3 '}>
                  <Sowmya />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutusindex;