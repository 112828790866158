import React from 'react';
import '../../career/careerform/MERNstack.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Careerimg from '../../../asset/career.png';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Imagereact from '../../../asset/icons/5.png'


const Nodejs = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Career - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/" />
            </Helmet>

            <div className='career_head_bg'>
                <div className={'pad'}>
                    <h3>Career</h3>
                    <p>Home / <span>Career</span> /<span>Nodejs</span></p>
                </div>
            </div>
            <div className={'pad'}>
                <div className={'career_head_content'}>
                    <div className={'row '}>

                        <div className={'col-lg-6  col-md-12 col-sm-12'}>
                            <img src={Imagereact} />
                            <p>We are seeking a highly skilled Node.js Developer to join our team. As a Node.js
                                Developer, you will be responsible for developing and maintaining server-side applications using
                                Node.js.
                            </p>
                            <h1 className={'pagehead'}> Responsibilities:</h1>
                            <ul>
                                <li>
                                    Design, develop, and maintain server-side applications using Node.js
                                </li>
                                <li>
                                    Collaborate with cross-functional teams to identify and solve complex problems
                                </li>
                                <li>
                                    Write high-quality, well-documented code
                                </li>
                                <li>
                                    Optimize server-side performance
                                </li>
                                <li>
                                    Debug and troubleshoot production issues

                                </li>
                                <li>
                                    Stay up-to-date with emerging trends and technologies in Node.js development
                                </li>
                            </ul>

                            <h1 className={'pagehead'}>Requirements:</h1>
                            <ul>
                                <li>
                                    Bachelor's degree in Computer Science or a related field
                                </li>
                                <li>
                                    Strong proficiency in JavaScript, including ES6+ syntax
                                </li>
                                <li>
                                    Experience with Node.js frameworks such as Express or Koa

                                </li>
                                <li>
                                    Experience with databases such as MongoDB, MySQL, or PostgreSQL

                                </li>
                                <li>
                                    Understanding of asynchronous programming and event-driven architecture
                                </li>
                                <li>
                                    Familiarity with version control systems such as Git

                                </li>
                                <li>
                                    Experience with cloud platforms such as AWS or Azure is a plus
                                </li>
                                <li>
                                    Strong problem-solving and communication skills

                                </li>
                                <li>
                                    Ability to work independently and as part of a team

                                </li>
                            </ul>
                            <p>If you are a self-motivated, detail-oriented Node.js Developer with a passion for building scalable,
                                high-performance applications, we would love to hear from you!
                            </p>
                        </div>

                        <div className={'col-lg-6  col-md-12 col-sm-12 mt-5'}>
                            <Form>
                                <div className={'row  career_form'}>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Name<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter name" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Phone Number<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="number" placeholder="Enter phone" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Email address<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="email" placeholder="Enter email" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4 " controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Experienced / Fresher<span>*</span></Form.Label>
                                            <Form.Select className="py-2" aria-label="Default select example">
                                                <option> -----select------ </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Previous or Current Employer<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter Previous or Current Employer" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>No of Experience<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter experience" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Upload Resume<span>*</span></Form.Label>
                                            <Form.Control className="py-2 " type="file" placeholder="Uplod resume" />
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Button className={'mt-4'} variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>


                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nodejs;