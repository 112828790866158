import React from 'react';
import '../../../homeeservices/services/services.css';
import Homeimg from '../../../../../../asset/homeservice.png';
import Homeserviceicon from '../../../../../../asset/icons/homeservice.png';
import { Helmet } from 'react-helmet';


const Homeservicepage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Homeservices | Home Services for All Your Home Needs</title>
        <meta name="title" content="Homeservices | Home Services for All Your Home Needs" />
        <meta name="description" content="Get professional home services at your
fingertips with one call. we provide a wide range of home maintenance and repair services " />
        <meta name="keywords" content=" home services, provide, wide range" />

      </Helmet>
      <div className={'homemade_bg'}>
        <div className={'pad'} >
          <h6>Homeservices</h6>
          <p><span>Home / </span> Homeservices</p>
        </div>
      </div>
      <div className={'Homemade_content'}>
        <div className={'pad'}>
          <div className={'row'}>
            <div className={'col-lg-3 col-md-3 col-sm-12'}>
              <img src={Homeimg} width='100%' />
            </div>
            <div className={'col-lg-9 col-md-9 col-sm-12'}>
              <div className={'homeservice_icon'}>
                <p>  <img src={Homeserviceicon} /> Homeservices</p>
              </div>
              <div className={'homemade_cont'}>
                <p>Our home service providers are highly skilled and experienced professionals who
                  specialize in a variety of services, including plumbing, electrical work, HVAC services, pest control,
                  and home cleaning. We understand that every home is unique, and we work closely with our clients to
                  provide tailored solutions that meet their specific needs. Our home services are designed to be
                  convenient and hassle-free.
                  <br></br> <br></br>

                  We offer flexible scheduling options to accommodate busy schedules,
                  and we use the latest tools and techniques to ensure that all work is done quickly and efficiently. We
                  also take great care to ensure that all work is done safely and to the highest standards of quality.

                </p>
              </div>
            </div>
            <div className={' homemade_cont col-lg-12 mt-3'}>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem
                Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing

              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homeservicepage;