import React from 'react'
import { Container } from 'react-bootstrap';
import Pickup from '../../../../../asset/icons/pickup.png';
import {BsFillArrowRightCircleFill} from 'react-icons/bs'

const Pickupndrop = () => {
  return (
    <div className={'text-center'}>
        <Container>
            <div className={'Pickup'}>
                <img src={Pickup}/>
               
            </div>
            <div className={'Pickup_head'}>
            <h5>Pickup & Drop</h5>
            <p>Read More <span> <BsFillArrowRightCircleFill/></span></p>
            </div>
           
        </Container>
    </div>
  )
}

export default Pickupndrop;