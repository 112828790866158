import React from 'react'
import { Container } from 'react-bootstrap';
import Resticon from '../../../../../asset/icons/rest.png';
import {BsFillArrowRightCircleFill} from 'react-icons/bs'

const Restaurant = () => {
  return (
    <div className={'text-center'}>
        <Container>
            <div className='restaurant'>
                <img src={Resticon}/>
            </div>
            <div className={'restaurant_head'} >
            <h5>Restaurant Food</h5>
            <p>Read More <span><BsFillArrowRightCircleFill/></span> </p>
            </div>
            
        </Container>
    </div>
  )
}

export default Restaurant;