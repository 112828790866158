import React from 'react';
import '../../../homeeservices/services/services.css';
import Homeimg from '../../../../../../asset/plant.png';
import Planticon from '../../../../../../asset/icons/plant.png';
import { Helmet } from 'react-helmet';


const Plantnnurserypage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Plant & Nursery | Buy Nursery Plants for Your Home | Quality Home Plants</title>
        <meta name="title" content="Plant & Nursery | Buy Nursery Plants for Your Home | Quality Home Plants" />
        <meta name="description" content="Shop our selection of quality, affordable
nursery plants for your home. With a variety of sizes
and species, we have the perfect addition to any room." />
        <meta name="keywords" content="nursery plants, species, variety of sizes" />
      </Helmet>
      <div className={'homemade_bg'}>
        <div className={'pad'} >
          <h6>Plant&nursery</h6>
          <p><span>Home / </span> Plant&nursery</p>
        </div>
      </div>
      <div className={'Homemade_content'}>
        <div className={'pad'}>
          <div className={'row'}>
            <div className={'col-lg-3 col-md-3 col-sm-12'}>
              <img src={Homeimg} width='100%' />
            </div>
            <div className={'col-lg-9 col-md-9 col-sm-12'}>
              <div className={'plant_icon'}>
                <p>  <img src={Planticon} />Plant & nursery</p>
              </div>
              <div className={'homemade_cont'}>
                <p> HOMEE is specialize in providing high-quality plants for all your gardening
                  and landscaping needs! Our nursery facility is dedicated to growing healthy, vibrant plants using
                  sustainable and environmentally friendly practices. Whether you're looking for beautiful flowers, lush
                  shrubs, or majestic trees, we have a wide variety of plant species and varieties to choose from. We
                  offer more than just plant sales - our team of experts can help you with all aspects of plant care, from
                  design and installation to maintenance and troubleshooting.

                  <br></br> <br></br>

                  Our goal is to help you create a beautiful
                  and thriving garden or landscape, and we're dedicated to providing the resources and support you
                  need to achieve your goals. HOMEE looks forward to helping you cultivate your own beautiful and
                  sustainable garden!



                </p>
              </div>
            </div>
            <div className={' homemade_cont col-lg-12 mt-3'}>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem
                Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing

              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plantnnurserypage;