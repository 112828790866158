import React from 'react';
import '../../career/careerform/MERNstack.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Careerimg from '../../../asset/career.png';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Imagereact from '../../../asset/icons/7.png';


const UInUXdesigner = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Career - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/" />
            </Helmet>

            <div className='career_head_bg'>
                <div className={'pad'}>
                    <h3>Career</h3>
                    <p>Home / <span>Career</span> /<span>UI & UX designer</span></p>
                </div>
            </div>
            <div className={'pad'}>
                <div className={'career_head_content'}>
                    <div className={'row '}>

                        <div className={'col-lg-6  col-md-12 col-sm-12'}>
                            <img src={Imagereact} />
                            <p> We are seeking a talented and experienced UI/UX Designer to join our team. As
                                a UI/UX Designer, you will be responsible for creating intuitive and visually appealing designs that
                                provide a seamless user experience for our customers
                            </p>
                            <h1 className={'pagehead'}> Responsibilities:</h1>
                            <ul>
                                <li>
                                    Collaborate with cross-functional teams to understand business requirements and user needs
                                </li>
                                <li>
                                    Create wireframes, prototypes, and visual designs for web and mobile applications
                                </li>
                                <li>
                                    Design intuitive user interfaces that provide a seamless user experience

                                </li>
                                <li>
                                    Conduct user research and testing to validate design decisions and iterate on designs

                                </li>
                                <li>
                                    Develop design concepts, user flows, and interaction models
                                </li>
                                <li>
                                    Stay up-to-date with industry trends and best practices in UI/UX design

                                </li>
                                <li>
                                    Work closely with developers to ensure designs are implemented accurately and efficiently

                                </li>
                            </ul>

                            <h1 className={'pagehead'}>Requirements:</h1>
                            <ul>
                                <li>
                                    Bachelor's degree in Graphic Design, HCI, Computer Science, or a related field

                                </li>
                                <li>
                                    Proficiency in design tools such as Sketch, Adobe XD, Figma, or InVision
                                </li>
                                <li>
                                    Strong understanding of user-centered design principles and best practices

                                </li>
                                <li>
                                    Experience with user research, user testing, and usability testing
                                </li>
                                <li>
                                    Familiarity with HTML, CSS, and JavaScript is a plus
                                </li>
                                <li>
                                    Familiarity with HTML, CSS, and JavaScript is a plus
                                </li>
                                <li>
                                    Excellent communication and collaboration skills
                                </li>
                                <li>
                                    Ability to work independently and as part of a team
                                </li>
                            </ul>
                            <p>If you are a talented and detail-oriented UI/UX Designer with a passion for creating visually appealing
                                and user-friendly designs, we would love to hear from you!
                            </p>
                        </div>

                        <div className={'col-lg-6  col-md-12 col-sm-12 mt-5'}>
                            <Form>
                                <div className={'row  career_form'}>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Name<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter name" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Phone Number<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="number" placeholder="Enter phone" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Email address<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="email" placeholder="Enter email" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4 " controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Experienced / Fresher<span>*</span></Form.Label>
                                            <Form.Select className="py-2" aria-label="Default select example">
                                                <option> -----select------ </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Previous or Current Employer<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter Previous or Current Employer" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>No of Experience<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter experience" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Upload Resume<span>*</span></Form.Label>
                                            <Form.Control className="py-2 " type="file" placeholder="Uplod resume" />
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Button className={'mt-4'} variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>


                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UInUXdesigner;