import React from 'react';
import '../../career/careerform/MERNstack.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Careerimg from '../../../asset/career.png';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Imagereact from '../../../asset/icons/6.png'


const Graphicdesigner = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Career - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/" />
            </Helmet>

            <div className='career_head_bg'>
                <div className={'pad'}>
                    <h3>Career</h3>
                    <p>Home / <span>Career</span> /<span>Graphicdesigner</span></p>
                </div>
            </div>
            <div className={'pad'}>
                <div className={'career_head_content'}>
                    <div className={'row '}>

                        <div className={'col-lg-6  col-md-12 col-sm-12'}>
                            <img src={Imagereact} />
                            <p>We are looking for a talented and creative Graphic Designer to join our team.
                                As a Graphic Designer, you will be responsible for creating visually appealing designs that effectively
                                communicate our brand message to our target audience.
                            </p>
                            <h1 className={'pagehead'}> Responsibilities:</h1>
                            <ul>
                                <li>
                                    Create designs for various digital and print media, including social media graphics, brochures,
                                    banners, posters, and other marketing materials
                                </li>
                                <li>
                                    Collaborate with cross-functional teams to conceptualize and execute design projects
                                </li>
                                <li>
                                    Create visual concepts, graphics, and layouts for brand identity and marketing campaigns

                                </li>
                                <li>
                                    Develop designs that align with our brand guidelines and effectively communicate our message to our
                                    target audience
                                </li>
                                <li>
                                    Stay up-to-date with industry trends and best practices in graphic design

                                </li>
                                <li>
                                    Manage multiple projects and deadlines while maintaining a high level of quality and attention to detail

                                </li>
                                <li>
                                    Work with stakeholders to understand their needs and translate them into visual designs


                                </li>
                            </ul>

                            <h1 className={'pagehead'}>Requirements:</h1>
                            <ul>
                                <li>
                                    Bachelor's degree in Graphic Design or a related field
                                </li>
                                <li>
                                    Proficiency in Adobe Creative Suite, including Photoshop, Illustrator, and InDesign

                                </li>
                                <li>
                                    Strong understanding of typography, color theory, and composition


                                </li>
                                <li>
                                    Experience creating designs for print and digital media

                                </li>
                                <li>
                                    Strong problem-solving skills and attention to detail
                                </li>
                                <li>
                                    Excellent communication and collaboration skills
                                </li>
                                <li>
                                    Ability to work independently and as part of a team
                                </li>
                            </ul>
                            <p>If you are a creative and detail-oriented Graphic Designer with a passion for visual storytelling and
                                branding, we would love to hear from you!
                            </p>
                        </div>

                        <div className={'col-lg-6  col-md-12 col-sm-12 mt-5'}>
                            <Form>
                                <div className={'row  career_form'}>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Name<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter name" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Phone Number<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="number" placeholder="Enter phone" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Email address<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="email" placeholder="Enter email" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4 " controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Experienced / Fresher<span>*</span></Form.Label>
                                            <Form.Select className="py-2" aria-label="Default select example">
                                                <option> -----select------ </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Previous or Current Employer<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter Previous or Current Employer" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>No of Experience<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter experience" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Upload Resume<span>*</span></Form.Label>
                                            <Form.Control className="py-2 " type="file" placeholder="Uplod resume" />
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Button className={'mt-4'} variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>


                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Graphicdesigner;