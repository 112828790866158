import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Raman_photo from '../../../asset/aboutus/Raman_Photo.jpg';
import '../../../app/aboutus/raman/raman.css';

const Raman = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <div className={'row py-3 av'} onClick={handleShow}>
                <div className={'col-lg-12'}>
                    <img src={Raman_photo} width="100%" />
                </div>
                <div className={'col-lg-12 mt-3 text-center'}>
                    <h5>MR.K. RAMAN</h5>
                    <p>Director of Homee</p>
                </div>
            
            </div>
            <Modal show={show} onHide={handleClose} centered  size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>MR.K. RAMAN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>PERSONAL PROFILE:</p>
                    <p>Aged 48 years.  Born in Middle class family to Mr.M S Kuppurao & Mrs. 
                        ShanthaKuppurao  as the  eldest son of 3 .  Studied in local regional 
                        medium till 10th std and English medium in higher secondary School .  
                        Graduated from Gurunanak College , Chennai in1993 and passed out ICWA in 2006.</p>
                        <br/> <br/>
                        <p>PROFESSIONAL PROFILE :</p>
                        <p>
                        Joined as an Accounts Asst. in 1994 in one of engineering companies of Chennai based Shriram Group and left the group as  Deputy General Manager – Finance in 2006 .
                        </p>
                        <br/>

                        <p>
                        During corporate experience  handled Treasury, Banking, Finance, MIS Functions.
                        </p>
                        <p>
                        Later joined M/s. Emas Engineers and Contractors Pvt. Limited., an Civil Engineering Corporate,  
                        held senior position (AVP) in Finance and Accounting Functions and was responsible for company’s 
                        entire finance and accounting functions. Involved in vendor Development in terms of negotiation 
                        with supplier / sub contractors for all construction materials and machineries. Fully involved in 
                        implementation of ERP with full control mechanism of site management. Having strong belief in Interpersonal / cordial working 
                        relationship to build up an organization and gained good support both from sub ordinates and superiors throughout the career 
                        so far. Capable of sourcing Materials and Machineries required for Civil Construction & Energy generation companies out of 
                        the these qualities developed over a period. Having an urge and vision to build up an organization with support of known 
                        erstwhile colleagues and with knowledge garnered in failed of finance, procurement and management over the period of time. 
                        </p>

                        <p>
                        PRESENT PROFILE :
                        </p>
                        <p>
                        Running the present organization since 2010 , as only major shareholder and putting efforts to change the unorganized sector 
                        having high potential of foreign inflows into a corporate style of management  to attract more countries and more overseas 
                        buyers,  by way of having young and experienced talents having bigger vision to take the organization to a respectful stature.
                        </p>
                      
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Raman;