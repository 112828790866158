import React from 'react'
import { Helmet } from 'react-helmet'

const ChefAgreement = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Cooks And Chefs Invitation - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/RefundCancellation" />
            </Helmet>
            <div className={'our_team_bg'}>
                <div className={'pad'}>
                    <div className={'our_team_head'}>
                        <h5>Cooks And Chefs Invitation</h5>
                        <p>Home / <span>Cooks And Chefs Invitation</span></p>
                    </div>
                </div>
            </div>


            <div className={'pad'}>
                <div className={'row mt-5'}>
                    <div className={'col-lg-6'}>
                        <p><b>Every homemaker who wants extra earnings out of their hidden skills in Cooking can be encashed through the HOMEE Cook
                            Platform by registering as a HOMEE Cook. The steps involved are as follows: </b></p>

                        <p> The HOMEE Cook App link is available in the Play Store. <a href='https://play.google.com/store/apps/details?id=com.homeeuser' >Click Here</a></p>
                        <p> Once you download the same, after the OTP, the Cook will get a message that our admin will contact you.</p>
                        <p> Once we Activate the cook, you will have to upload the following documents without fail:</p>
                        <p> Your Aadhar copy if you are cooking in an individual name or your GST certificate if you are going to sell in any establishment or shop name</p>
                    </div>

                    <div className={'col-lg-6'}>
                        <p> FSSASI Certificate: This is also a basic Document required to be uploaded. If the cook does not have the FSSAI certificate, the HOMEE team will
                            coordinate with you to get you the required FSSAI Certificate.</p>

                        <p> In the meantime, you can start adding your favourite menus under various food categories and food timings so that those relevant foods will
                            be visible to the users of the HOMEE APP. Most importantly, the prices of each of your foods will be fixed by you only, so that upon sale and
                            delivery through the HOMEE APP, you will get your money for the relevant food price within 24 to 48 hours of the delivery.</p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ChefAgreement
