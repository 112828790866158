import React from 'react';
import '../../career/careerform/MERNstack.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Careerimg from '../../../asset/career.png';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Imagereact from '../../../asset/icons/3.png'


const Java = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Career - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/" />
            </Helmet>

            <div className='career_head_bg'>
                <div className={'pad'}>
                    <h3>Career</h3>
                    <p>Home / <span>Career</span> /<span>Java</span></p>
                </div>
            </div>
            <div className={'pad'}>
                <div className={'career_head_content'}>
                    <div className={'row '}>

                        <div className={'col-lg-6  col-md-12 col-sm-12'}>
                            <img src={Imagereact} />
                            <p>We are seeking a skilled Java developer to join our team. In this role, you will be responsible
                                for designing and developing high-quality, scalable software applications using Java. You will work
                                closely with other developers and stakeholders to ensure our products meet the needs of our clients.
                            </p>
                            <h1 className={'pagehead'}> Responsibilities:</h1>
                            <ul>
                                <li>
                                    Design, develop, and maintain software applications using Java

                                </li>
                                <li>
                                    Collaborate with other developers and stakeholders to define and implement new features
                                </li>
                                <li>
                                    Write clean, maintainable code that adheres to coding standards
                                </li>
                                <li>
                                    Test and debug applications to ensure they meet quality standards
                                </li>
                                <li>
                                    Document code and processes to ensure knowledge sharing among the team
                                </li>
                                <li>
                                    Stay up-to-date with emerging trends and technologies in software development
                                </li>
                            </ul>

                            <h1 className={'pagehead'}>Requirements:</h1>
                            <ul>
                                <li>
                                    Proficient in JavaScript, HTML, CSS, and related web technologies
                                </li>
                                <li>
                                    Familiarity with web development technologies such as HTML, CSS, and JavaScript
                                </li>
                                <li>
                                    Experience with database systems such as MySQL or Oracle

                                </li>
                                <li>
                                    Strong problem-solving skills and attention to detail

                                </li>
                                <li>
                                    Excellent communication and teamwork abilities
                                </li>
                                <li>
                                    A passion for creating high-quality software applications

                                </li>

                            </ul>
                            <p>If you have a passion for Java development and are looking for an exciting new opportunity to work
                                on cutting-edge software applications, we encourage you to apply for this position. We offer a
                                competitive salary, comprehensive benefits package, and a dynamic and collaborative work
                                environment
                            </p>
                        </div>

                        <div className={'col-lg-6  col-md-12 col-sm-12 mt-5'}>
                            <Form>
                                <div className={'row  career_form'}>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Name<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter name" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Phone Number<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="number" placeholder="Enter phone" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Email address<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="email" placeholder="Enter email" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4 " controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Experienced / Fresher<span>*</span></Form.Label>
                                            <Form.Select className="py-2" aria-label="Default select example">
                                                <option> -----select------ </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Previous or Current Employer<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter Previous or Current Employer" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>No of Experience<span>*</span></Form.Label>
                                            <Form.Control className="py-2" type="text" placeholder="Enter experience" />
                                        </Form.Group>
                                    </div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12'}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className={'f_lable'}>Upload Resume<span>*</span></Form.Label>
                                            <Form.Control className="py-2 " type="file" placeholder="Uplod resume" />
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Button className={'mt-4'} variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>


                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Java;