import React from 'react';
import Headicon from '../../../../asset/headicon.png';
import '../../../home/section/aboutuserapp/aboutuserapp.css';
import Userapp from '../../../../asset/userapp.png';
import Playstore from '../../../../asset/playstore.png';
import Applestore from '../../../../asset/applestore.png';

const Aboutuserappindex = () => {
  return (
    <div>
      <div className={'pad at_userapp'}>
        <div className={'row justify-content-center  '}>
          <div className={'col-lg-12 text-center at_userapp_head '}>
            <img src={Headicon} />
            <h1>About <span>Homee User App</span> </h1>
          </div>
          <div className={'col-xl-4 col-lg-12 col-md-12 col-sm-12 py-5   '}>
            <div className={'at_userapp_content'}>
              <h3><span>01</span> Instant Order</h3>
              <p>HOMEE offers a wide selection of delicious meals from your favorite local restaurants that can be delivered straight to your doorstep in no time.
                We provide an easy and user-friendly interface that allows you to browse and select from a range of options, place your order, and track it in real-time.
                So the next time you're craving a delicious meal,
                head over to HOMEE and place your order with just a few clicks!

              </p>
            </div>

            <div className={'at_userapp_content mt-5 '}>
              <h3> <span>02</span> Advance Order</h3>
              <p>Are you looking for a quick and easy way to satisfy your hunger cravings?
                Look no further than HOMEE's instant food order! We offers a wide variety of fast and delicious food
                options that can be prepared in a flash.
                We understand that life can get busy, and cooking a full meal may not always be feasible. That's why we're here to make your life a little easier by providing you
                with fast and convenient food options. Don't let hunger stress you out - order from HOMEE today!

              </p>
            </div>
          </div>

          <div className={'col-xl-4 col-lg-6 col-md-12 col-sm-12 mt-3  px-3 text-center user_app  '}>
            <img src={Userapp} width='100%' />
          </div>

          <div className={'col-xl-4 col-lg-6 col-md-12 col-sm-12 py-5'}>
            <div className={'at_userapp_content'}>
              <h3> <span>03</span> 4 in 1 Services.</h3>
              <p> {`where convenience meets purchasing! We are happy to provide
               a simplified online purchasing experience that will meet all 
               of your needs. If you're looking for the most recent food delivery, 
               home supplies, plants, or pick-up and drop-off service, we've got you covered.`}
              </p>
            </div>

            <div className={'user_store'}>
              <h6>Available in Both Platforms</h6>
              <div className={'d-flex justify-content-start'}>
                <div>
                  <a href='https://play.google.com/store/apps/details?id=com.homeeuser'>

                    <img src={Playstore} width='100%' />
                  </a>
                </div>
                <div>
                  <a href='https://apps.apple.com/in/app/homee/id1603826750'>

                    <img src={Applestore} width='100%' />
                  </a>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Aboutuserappindex;