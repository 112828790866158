import React from 'react';
import '../../../app/blog/blogpage/blogpage.css';
import Blogimg from '../../../asset/blogimg.png';
import { MdDateRange } from 'react-icons/md'

const Blogpage = () => {
    return (
        <div>
            <div className={'blogpage_bg'}>
                <div className={'pad'}>
                    <h5>Blog</h5>
                    <p><span>Home / </span> <span>Blog / </span> <span>Blog Content </span></p>
                </div>
            </div>

            <div className={'blogpage_content'}>
                <div className={'pad'}>
                    <div className={'row'}>
                        <div className={'col-lg-4'}>
                            <img src={Blogimg} width="100%" />
                        </div>
                        <div className={'col-lg-8 blog_side_cont'}>
                            <p><MdDateRange /> JANUARY 9, 2023</p>

                            <h1>7-Layer Beet Salad</h1>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                1500sLorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem
                                Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy
                                text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>

                        </div>
                        <div className={'col-lg-12 blog_side_cont mt-3'}>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the
                                1500sLorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem
                                Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy
                                text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Blogpage;