import React from 'react';
import { Container } from 'react-bootstrap';
import Homemade from './services/homemade';
import Homeservice from './services/homeservice';
import Pickupndrop from './services/pickupndrop';
import Plantnnursery from './services/plantnnursery';
import Restaurant from './services/restaurant';
import Headicon from '../../../../asset/headicon.png';
import '../../../home/section/homeeservices/homeservice.css'
import { Link } from 'react-router-dom';


const Homeeserviceindex = () => {
    return (
        <div className={'bg'}>
            <Container>
                <div className={"row d-flex justify-content-evenly align-items-center"}>
                    <div className={'col-lg-12 text-center mt-4 homeeservice '}>
                        <img src={Headicon} />
                        <h1>Homee <span>Services</span></h1>
                    </div>

                    <div className={'col-lg-2 col-md-4 col-sm-6 col-6 py-1'}>
                        <Link to={'homemade'}>
                            <Homemade />
                        </Link>
                    </div>
                    <div className={'col-lg-2 col-md-4 col-sm-6 col-6  py-1'}>
                        <Link to={'restaurantfood'}>
                            <Restaurant />
                        </Link>
                    </div>
                    <div className={'col-lg-2 col-md-4 col-sm-6 col-6  py-1'}>
                        <Link to={'plant&nursery'}>
                        <Plantnnursery />
                        </Link>
                    </div>
                    <div className={'col-lg-2 col-md-6 col-sm-6 col-6 text-center  py-1'}>
                        <Link to={"Pickup&drop"}>
                        <Pickupndrop />
                        </Link>

                    </div>
                    <div className={'col-lg-2 col-md-6 col-sm-6 col-6 text-center  py-1'}>
                        <Link to={'homeservices'}>
                        <Homeservice />
                        </Link>

                    </div>


                </div>
            </Container>


        </div>

    )
}

export default Homeeserviceindex;