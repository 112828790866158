import React from 'react'
import { Helmet } from 'react-helmet'

const CaterersSaviour = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Caterers Saviour - Homee Onetouch Platform</title>
                <link rel="canonical" href="https://homeeonetouchplatform.com/RefundCancellation" />
            </Helmet>
            <div className={'our_team_bg'}>
                <div className={'pad'}>
                    <div className={'our_team_head'}>
                        <h5>Caterers Saviour</h5>
                        <p>Home / <span>Caterers Saviour</span></p>
                    </div>
                </div>
            </div>


            <div className={'pad'}>
                <div className={'row mt-5'}>
                    <div className={'col-lg-6'}>
                        <p> HOMEE Cook app has the special feature of HOMEE ONE TO ONE, especially for daily caterers. Many small caterers are struggling
                            to expand their customer base due to a lack of delivery facilities, which HOMEE is trying to solve to the maximum extent by way of HOMEE ONE TO ONE.</p>

                        <p> Under this special feature ,  caterers in their Dash board of the HOMEE cook app, after registration as per the procedure mentioned above, will see a
                            field called My Customer. If the caterers or cooks touch that ,  there will be two fields: one is Name and the other is number. If caterers fill in
                            both fields with the names and numbers of their dedicated customers, a WhatsApp message will be sent as an invitation from the caterers to that customer.
                            Upon clicking the d downloadlink of H theMEE User app, that caterer’s c customerswill become HOMEE users, ut that user can iew only that C caterer'sfood
                            and menu.o that caterers need not worry that their customers will go to the different caterers under the HOMEE APP.</p>
                    </div>

                    <div className={'col-lg-6'}>
                        <p>Whenever the food is made ready by caterers, on the set time, as prescribed by that caterer,  a trigger will be made from our HOMEE Back end automatically.
                            Once delivery alters are triggered, the alert will be sent to the nearest HOMEE Delivery Partner, who will come to the respective caterer's place and
                            pick up the food and start delivering to all the customers for whom the delivery was made ready by the caterers,  respectively in accordance with the
                            short distance to long distance from the place of caterers.</p>

                        <p>We as a Homee team, being in the shoes of Caterers, find it a saviour to all those who do regular catering service on a daily basis on all 3 times in a day to set
                            of customers, and also they can expand their reach to various customers without concentrating on delivery work,  which is taken care of by Homee.</p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CaterersSaviour
