import React from 'react';
import Headicon from '../../../../asset/headicon.png';
import '../../../../app/home/section/aboutcookapp/aboutcookapp.css'
import Faq from "react-faq-component";
import Userapp from '../../../../asset/userapp.png'

const Aboutcookapp = () => {

  const data = {
    // title: "FAQ (How it works)",
    rows: [
      {
        title: "Vendors invite,",
        content: `Welcome to our advanced e-commerce site, where convenience meets purchasing! We are happy to provide a 
        simplified online purchasing experience that will meet all of your needs. We've got you covered if you're looking 
        for the most recent food delivery, plants, and nursery, pick-up and drop-off service, home service.`,
      },

    ],
  };

  const styles = {
    bgColor: 'white',
    // titleTextColor: "#4d4d4d",
    // rowTitleColor: "4d4d4d",
    // rowContentColor: 'grey',
    arrowColor: "#028149",
    transitionDuration: ".5s",
    timingFunc: "ease",
    rowTitleTextSize: '20px',

  };

  const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true
  };


  return (
    <div className={'cookapp'}>
      <div className={'pad'}>
        <div className={'row'}>
          <div >

          </div>
          <div className={'col-lg-7 mt-3'}>
            <div className={'cl-lg-12 headicon'}>
              <img src={Headicon} />
              <h1 className={'mt-3'}>About <span>Homee Cook App</span></h1>
            </div>
            <div>
              {/* <Faq
                data={data}
                styles={styles}
                config={config}
                className={'faq'}

              /> */}
              <p>
                HOMEE, which now connects small eateries, regular caterers, and homemakers for delivering homely-type foods, including home-made batters and
                condiments, in Town for some time, Developing its platform to bring in other services soon. It has more than 600 kitchens in Chennai and 100
                in Hyderabad and adds to its Kitchen base on a daily basis. HOMEE will be a mini-super App for Regular home-based and restaurant foods, Delivery
                services, Home Garden products, copoeat, Home Keeping services, and others. The HOMEE name was coined to meet most of the home's needs through its
                tech platform. HOMEE Brand aims to become a key Tech Member in every house over time.
                <br /><br />
                HOMEE is owned by First Gen E-COM India Pvt. Ltd., promoted by K. Raman and Sowmya in 2022, working with their team for the development.
                HOMEE has plans to bring in more than 100,000 Active users to the platform by the end of March 2024. The promoter's ultimate goal of the
                business model will be to make this venture fully EBITDA-positive in the shortest time possible by reducing the costs of acquiring a customer
                and delivering the various product and service options under a single Mobile Application before starting cash burns. And we will also keep
                creating models for generating revenues through subscription-based income in the coming days.
                <br /><br />
                It has the Special feature of Placing orders in Advance for the next week. HOMEE One-to-One service will be a saviour for all the daily meal 
                caterers who serve the Senior Citizens and Bachelors. HOMEE will enable the delivery of food and other items through the App to the doorsteps 
                of those users who do not have enough experience ordering online. HOMEE has understood that these caterers are struggling to expand their reach 
                due to a lack of Delivery facilities, which will be fulfilled by HOMEE. At present, the company is self-funded by the promoters and is looking 
                for Equity or Strategic Investors to expand its presence in more cities in the coming days.
           

              </p>
            </div>
          </div>
          <div className={'col-lg-5 px-5'}>
            <div>
              <img src={Userapp} width="100%" />
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Aboutcookapp;