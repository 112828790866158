import React from 'react';
import '../../../homeeservices/services/services.css';
import Homeimg from '../../../../../../asset/pickup.png';
import Pickupicon from '../../../../../../asset/icons/pickup.png';
import { Helmet } from 'react-helmet';


const Pickupndroppage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pickup & Drop | Reliable Pick-Up & Drop-Off Services for All Your Needs</title>
        <meta name="title" content="Pickup & Drop | Reliable Pick-Up & Drop-Off Services for All Your Needs" />
        <meta name="description" content="We provide reliable and affordable pickup and drop-off services for all your needs. Whether you need a ride to the airport, grocery store, or anywhere else " />
        <meta name="keywords" content=" pick up, drop, off all your needs" />

      </Helmet>
      <div className={'homemade_bg'}>
        <div className={'pad'} >
          <h6>Pickup & drop</h6>
          <p><span>Home / </span> Pickup & drop</p>
        </div>
      </div>
      <div className={'Homemade_content'}>
        <div className={'pad'}>
          <div className={'row'}>
            <div className={'col-lg-3 col-md-3 col-sm-12'}>
              <img src={Homeimg} width='100%' />
            </div>
            <div className={'col-lg-9 col-md-9 col-sm-12'}>
              <div className={'pickup_icon'}>
                <p>  <img src={Pickupicon} /> Pickup & drop</p>
              </div>
              <div className={'homemade_cont'}>
                <p>HOMEE provides fast, reliable, and convenient transportation services for all
                  your delivery needs! Our pickup and drop services are designed to meet the needs of both individuals
                  and businesses. Whether you need to send a package to a friend across town or deliver a shipment
                  to a customer in another state, we have the resources and expertise to make it happen. We offer on demand pickup and drop services, meaning that you can request a pickup or delivery at any time and
                  we'll be there to help.

                  <br></br>  <br></br>
                  Our team of experienced drivers and delivery personnel are committed to
                  providing prompt and courteous service, and we strive to meet all delivery deadlines with accuracy
                  and efficiency. We're committed to providing the highest level of customer service and satisfaction.



                </p>
              </div>
            </div>
            <div className={' homemade_cont col-lg-12 mt-3'}>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem
                Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard dummy text ever since the 1500sLorem Ipsum is simply dummy text of the printing

              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pickupndroppage;