import React from 'react';
import { Container } from 'react-bootstrap';
import Homeservices from '../../../../../asset/icons/homeservice.png';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
const Homeservice = () => {
  return (
    <div className={'text-center'}>
      <Container>
        <div className={'homeservice'}>
          <img src={Homeservices} />

        </div>
        <div className={'homeservice_head'}>
          <h5>Home services</h5>
          <p>Read More <span> <BsFillArrowRightCircleFill /></span> </p>
        </div>

      </Container>
    </div>
  )
}

export default Homeservice;